.newMeetingPage {
	display: flex;
	flex-direction: column;
	height: 100vh;
	overflow: auto;
	position: relative;
}

.newMeetingPaper {
	direction: rtl;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 2em;
}

.newMeetingExplaination {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 2em;
}

.removeRecording {
	position: absolute;
    right: 2em;
	top: 5em;
	display: flex;
	flex-direction: column;
}

.whatsappHelp {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: 8em;
}

.whatsappHelp img{
	width: 2em;
	height: 2em;
}

@media only screen and (max-width: 600px) {
	.newMeetingPage {
		flex: 1 0 auto;
	}

	.newMeetingPaper {
		width: unset;
		height: unset;
		flex: 1 0 auto;
	}

	.whatsappHelp {
		position: absolute;
		top: 20em;
		margin-top: unset;
	}

	
	.newMeetingExplaination {
		margin: 2em 0.5em;
		flex: 1 0 auto;
	}

	.newMeetingDescription {
		margin: 1em 0 !important;
	}
}