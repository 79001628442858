.infoContainer {
	padding-right: 5em;
	display: flex;
	flex-direction: column;
	font-size: 0.9em;
	justify-content: right;

	direction: rtl;
	width: 83%;
	height: calc(100vh - 3.2em);
    overflow-y: auto;
    margin: auto;
    background-color: whitesmoke;
	padding-top: 5.2em;
	min-width: 75em;
}

.infoContainer * {
	color: #6D6D6D;
}

.infoTitle {
	font-size: 20px;
	font-weight: bold;
	margin-top: 3em;
	margin-bottom: 0.7em;
}

.downloadOptions {
	display: flex;
	flex-direction: row;
	margin-top: 1em;
	margin-right: 2em;
	align-items: center;
}

.downloadOptions img {
	width: 10em;
	cursor: pointer;
	margin-right: 1em;
}

.downloadOptions a:first-of-type img {
	width: 10.68em;
}

.infoSection {
	display: flex;
	flex-direction: right;
	align-items: flex-end;
	margin-bottom: 2em;
	margin-top: 0.3em;
}

.orange {
	width: 2em;
	height: 5em;
	background-color: #FFCA4B;
	margin-left: 0.5em;
}

.bigTitle {
	font-size: 30px;
	font-weight: bold;
	color: #6D6D6D;
	margin: 1em 0;
}

.infoAndPhoto {
	display: flex;
	flex-direction: row;
}

.infoAndPhoto img {
	height: 25em;
	margin-left: 2em;
}

@media only screen and (max-width: 600px) {
	.infoAndPhoto {
		flex: 1 0 auto;
	}
	
	.infoAndPhoto img {
		display: none;
	}

	.infoContainer {
		padding-top: 0em;
		padding-right: 2em;
		min-width: 20em;
		margin-top: 0em;
		height: unset;
	}

	.orange {
		display: none;
	}

	.infoSection {
		max-width: 90%;
	}

	.downloadOptions {
		flex-direction: column;
		margin-right: -3em;
		margin-bottom: 1.5em;
		flex: 1 0 auto;
	}

	.bigTitle {
		margin-top: 1em;
	}
}