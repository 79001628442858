.timePicker{
	width: 2em !important;
	padding: 0 0.2em !important;
	height: 2em;
	font-weight: bold !important;
}

.timeLabel {
    position: relative;
    right: -2.1em;
    top: 0.7em;
	font-size: 14px;
}

.timePickerWithLabel {
	background-color: transparent;
	margin-right: 0.5em;
	margin-top: -0.5em;
}

.timePickerWrapper {
	margin-right: 0.3em !important;
	margin-left: 0.5em !important;
	display: flex;
	flex-direction: row;
	align-items: center;
}
