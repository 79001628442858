.recordingNameInput {
    display: flex;
    flex-direction: row;
    margin: 0.5em;
    align-items: center;
}

.recordingNameInput > * {
    margin-left: 0.5em !important;
}

.recordingNameInput .MuiOutlinedInput-input {
    padding: 0.8em 1em !important;
}
