.dialogDescription {
    margin-top: 1em;
    margin-bottom: 1em;
}

.paymentDialog {
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#dialogButton {
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: #d82b42;
    color: white;
    border-radius: 2.8em;
}

.paymentDetails {
    display: flex;
    flex-direction: row;
    margin-top: 1em;
}

.detailsTitles {
    display: flex;
    flex-direction: column;
    width: 8em;
}

.detailsValues {
    display: flex;
    flex-direction: column;
}

.offerDetail {
    color: rgb(216, 43, 66);
    text-align: center;
    font-size: 14px;
    margin-bottom: 1em !important;
}

.recordingsSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recordingsNamesInput {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 35vh;
    overflow-y: auto;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.paymentDialogPresentation {
    z-index: 3000 !important;
}

.addRecordingButton {
    font-weight: bold;
}

.paymentDialog .Mui-disabled {
    background-color: lightgray !important;
    color: gray !important;
}

@media only screen and (max-width: 600px) {
    .paymentDialog .title {
      font-size: 1.3em;
    }
}

.speakWithAgent {
    margin: 10px;
}
