#meetingPage {
    display: flex;
    flex-direction: column;
    direction: rtl;
    background-color: white;
    height: calc(100vh - 11em);
    margin-right: 7em;
    margin-left: 7em;
    min-width: 40em;
    margin-top: 3em;
    overflow-y: auto;
    padding-bottom: 8em;
}

.columnFlex {
    display: flex;
    flex-direction: column;
}

.subtitlesToggle {
	position: fixed;
	bottom: 5em;
	left: 1.5em;
	display: flex !important;
	flex-direction: column;
}

.ltrSwitch {
	position: fixed;
	bottom: 1em;
	left: 1.5em;
	display: flex !important;
	flex-direction: column;
}
