.newMeetingHeaderWrapper {
	padding-top: 5em;
	height: 20%;
	direction: rtl;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	align-items: center;
	min-width: 60em;
}

.newMeetingHeaderWrapper .MuiInputBase-root {
	width: 15em;
	height: 2.2em;
    background-color: white;
	border: 1px solid black;
}

.newMeetingHeader {
	margin-top: 2em;
	direction: rtl;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
}

.meetingHeaderInput {
	display: flex;
	flex-direction: column;
	margin: 0 2em;
}

.newMeetingUploadButtonWrapper span {
	font-weight: bold !important;
}

.newMeetingUploadButtonWrapper {
	position: absolute;
    left: 2em;
    top: 7.5em;
    padding: 0.8em !important;
	margin-right: 3em !important;
}

.sendTooltip {
	width: 8em !important;
	text-overflow: clip;
	overflow: hidden;
	background-color: white !important;
	border: 1px solid lightgray !important;
}

@media only screen and (max-width: 600px) {
	.newMeetingHeaderWrapper {
		min-width: 20em;
		padding-top: 12em;
		height: 20em;
		flex: 1 0 auto;
	}

	.newMeetingHeader {
		flex-direction: column;
		flex: 1 0 auto;
	}
}