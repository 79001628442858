.meetingItem {
    display: flex;
    flex-direction: row;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    align-items: center;
    padding-left: 0.8em;
    width: 100%;
}

.metadataTooltip {
    direction: rtl;
    display: flex;
    flex-direction: row;
    background-color: whitesmoke;
    border: black 1px solid;
    border-radius: 0.5em;
    color: black;
    font-size: 1.2em;
    padding: 0.5em 1em;
    margin-bottom: -1.5em;
}

.metadataTooltip > * {
    display: flex;
    flex-direction: column;
}

.metadataTooltip > div:first-of-type {
    width: 6em;
}

.dashboard {
    width: 3em;
}

.meetingName {
    width: 19em;
    -webkit-touch-callout: auto; /* iOS Safari */
    -webkit-user-select: auto; /* Safari */
    -khtml-user-select: auto; /* Konqueror HTML */
    -moz-user-select: auto; /* Old versions of Firefox */
    -ms-user-select: auto; /* Internet Explorer/Edge */
    user-select: auto;
}

.meetingName .ltr {
    direction: ltr;
    unicode-bidi: bidi-override;
}

.meetingLength {
    width: 6em;
}

.recordingName {
    width: 8em;
}

.meetingDate {
    width: 5em;
}

.ready {
    width: 7.5em;
}

.sent {
    width: 7.5em;
}

.meetingParticipantsNum {
    width: 5em;
}

.meetingCount {
    width: 3.7em;
}

.meetingOwner {
    width: 8em;
}

.goToMeeting {
    width: 3em;
    margin-left: 0.5em;
}

.readyButton {
    width: 7.5em;
}
