.dialogDescription {
    margin-top: 1em;
    margin-bottom: 1em;
}

.loadingDialog {
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadingDialog .MuiTypography-caption {
    font-size: unset;
}

.loadingDialog .loadingProgressBox > .MuiBox-root {
    font-size: 22px;
}

.loadingDialog .loadingProgressBox {
    margin-top: 1.2em;
    margin-bottom: 1em;
}

.loadingDialog .MuiCircularProgress-root {
    width: 7em !important;
    height: 7em !important;
}

@media only screen and (max-width: 600px) {
    .loadingDialog .title {
      font-size: 1.3em;
    }
}