#topBar {
    position: fixed;
    top: 0;
    width: 100%;
    height: 3em;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: row;
    direction: rtl;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
    z-index: 2000;
}

.topBarItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2em;
    margin-right: 2em;
    flex-wrap: wrap;
}

.actionButton {
    direction: rtl !important;
    margin-right: 0.5em !important;
}

#topBar .MuiButton-root {
    color: rgb(216, 43, 66);
}

#topBar .MuiButton-outlined {
    border: 1px solid rgb(216, 43, 66);
}

.exportMenu .MuiMenu-paper { 
    top: 3.5em !important;
    direction: rtl;
}

.whatsappContainer {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-right: 4em;
    font-size: 14px;
}

.whatsappContainer img{
	width: 2em;
	height: 2em;
	margin-left: 0.5em;
}

.Mui-selected .MuiTab-wrapper {
    font-weight: bold !important;
}

.MuiTabs-indicator {
    background-color: #FFBD1E !important;
    height: 5px !important;
}

#topBar .MuiDivider-vertical {
    width: 2px !important;
    height: 2.3em !important;
    margin: auto !important;
    margin-right: 2em !important;
    margin-left: 1em !important;
}

.logoItem {
    height: 70%;
}

.forMobile {
    display: none;
    font-size: 14px;
    padding-bottom: 4px;
}

.whatsappContainerMobile {
    display: none;
}

@media only screen and (max-width: 600px) {
    #topBar {
        height: unset;
        flex-direction: column-reverse;
        position: unset;
    }

    .whatsappContainer {
        display: none;
        margin-right: 0;
        margin-top: 0.5em;
    }

    .whatsappContainerMobile {
        display: flex;
        flex-direction: row;
        align-self: flex-start;
        margin-right: 1em !important;
    }

    #topBar .MuiDivider-vertical {
        display: none;
    }

    .logoItem {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
        height: 3em;
    }

    .notForMobile {
        display: none;
    }

    .forMobile {
        display: unset;
    }

    .topBarItem {
        max-width: 22em;
        margin-left: unset;
        margin-right: unset;
    }

    .actionButton {
        margin-bottom: 0.3em !important;
        width: 7em;
        overflow: hidden;
    }

    .exportMenu {
        z-index: 3000 !important;
    }

    .exportMenu .MuiMenu-paper { 
        top: 8em !important;
        direction: rtl;
    }
}