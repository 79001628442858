.textRange {
    display: flex;
    flex-direction: row;
    margin-right: 4em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}

.textRange.passed {
    opacity: .4;
}

.textRange.subtitles.overlapping .textRangeField {
    background: #e7b1b1;
}

.LTR.textRange {
    display: flex;
    flex-direction: row-reverse;
    margin-left: 4em;
    margin-top: 0.3em;
    margin-bottom: 0.3em;
}

.textRange .MuiTextField-root {
    width: 75%;
}

.LTR.textRange .MuiTextField-root {
    width: 75%;
}

.textRange .rangeSpeaker {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1em;
}

.LTR.textRange .rangeSpeaker {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    font-size: 1em;
}

.rangeTitle .subtitleRange {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.rangeTitle .subtitleRange .timestamps {
    display: flex;
    flex-direction: column;
    width: 100%
}

.rangeTitle .subtitleRange .timestamps .timeInMeeting {
    margin-right: 0;
}

.rangeTitle .subtitleRange .timestamps .timepicker {
    direction: ltr;
    padding: 3px;
}

.rangeTitle .subtitleRange .timestamps .timepicker span {
    color: #5d5d5d;
}

.rangeTitle .subtitleRange .timestamps .timepicker input {
    color: #5d5d5d;
    font-size: 14px;
    letter-spacing: -0.4px;
    padding: 0;
}

.textRange.overlapping .rangeTitle .subtitleRange .timestamps .timepicker input,
.textRange.overlapping .rangeTitle .subtitleRange .timestamps .TimerPicker input,
.textRange.overlapping .rangeTitle .subtitleRange .timestamps .TimerPicker span {
    color: #d23030;
}

.rangeTitle .subtitleRange .timestamps .timepicker input:hover {
    cursor: pointer;
}

.rangeTitle .subtitleRange .timestamps .timepicker input:focus {
    outline: 0;
}

.rangeTitle .subtitleRange .timestamps .timepicker .react-time-picker__wrapper {
    color: #5d5d5d;
    border: 0;
}

.rangeTitle .subtitleRange .timestamps .timepicker .react-time-picker__wrapper:hover input {
    color: #3c3c3c;
}

.rangeTitle .subtitleRange .timestamps .timepicker .react-time-picker__wrapper:hover span {
    color: #3c3c3c;
}

.rangeTitle .subtitleRange .timestamps input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
    display: none;
}

.rangeTitle {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 8em;
}

.timeInMeeting {
    margin-right: 1.8em;
    color: #5d5d5d;
    font-size: 1em;
    margin-top: 0.2em;
}

.altersTooltip {
    display: flex;
    flex-direction: row;
}

.altersTooltip span {
    margin-right: 0.2em;
    padding: 0.5em;
    color: red;
    background-color: white;
    border: 1px solid black;
    border-radius: 3px;
    cursor: pointer;
}

.MuiTooltip-tooltip {
    background-color: transparent !important;
}

.react-contextmenu-wrapper {
    margin-bottom: 1em;
    flex-flow: wrap;
    max-width: 75%;
    display: flex;
    flex-direction: row;
    align-self: center;
}
