.calculatorWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 32em;

	width: 75%;
	height: calc(100vh - 4em);
    overflow-y: auto;
    overflow-x: auto;
    margin: auto;
    background-color: whitesmoke;
	color: #6D6D6D !important;
    padding-top: 4em;
    min-width: 65em;
    position: relative;
}

.calculatorWrapper > * {
    margin-top: 1em !important;
}

.calculatorWrapper .MuiButton-root {
    color: #333333;
    background-color: #FFBD1E;
    padding: 0.5em 1.2em;
    margin-top: 1em;
}

.calculatorWrapper .MuiButton-root b {
    font-weight: bold;
    margin-left: 0.3em;
}

.calculatorWrapper .MuiDivider-root {
    height: 1px;
    background-color: #FFBD1E;
    width: 70%;
    margin: 1em 0;
}

.titleWithSmallDetail {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-top: 1em;
}

.smallDetail {
    font-size: 12px;
}

.priceHoldersWrapper {
    position: absolute;
    left: 5em;
    top: 21em;
    flex: 1 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
}

.steps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.steps > * {
    align-items: center;
    flex-direction: column;
    display: flex;
    margin: 0.7em;
    width: 10em;
    flex: 1 0 auto;
}

.steps > * > span{
    text-align: center;
    font-size: 14px;
}

.steps > * > img {
    width: 3.5em;
    margin-bottom: 1em;
}

.calculatorTitle {
    margin-top: 3em;
    font-size: 18px;
}

.subtitlesService {
    color:rgb(216, 43, 66);
    transform: rotate(320deg);
    position: absolute;
    text-align: center;
    left: 4em;
    top: 10em;
}

.calculatorWrapper > *:first-child {
    margin-top: 1em !important;
}

.uploadButton .MuiButton-label {
    font-size: 20px;
}

.uploadButton {
    max-width: 70vw;
    padding: 1em !important;
}

@media only screen and (max-width: 600px) {
    .steps {
        flex-direction: column;
        flex: 1 0 auto;
    }

    .titleWithSmallDetail {
        flex: 1 0 auto;
    }

    .calculatorWrapper {
        min-width: 20em;
        height: unset;
    }

    .calculatorWrapper > *:first-child {
        margin-top: 0em !important;
    }

    .priceHoldersWrapper {
        position: unset;
    }

    .uploadButton {
        margin-bottom: 3em !important;
    }

    .subtitlesService {
        transform: rotate(0deg);
        position: unset;
    }
}