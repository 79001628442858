.rowFlex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 0.8em;
}

.rowFlex > * {
    margin-bottom: 0.5em;
}

.columnFlex {
    display: flex;
    flex-direction: column;
}

.header {
    margin-top: 2em;
    justify-content: space-between;
}

.meetingNameHeader {
    margin-right: 1em;
    margin-left: 1em;
}

.meetingPriceHeader {
    margin-right: 1em;
    margin-left: 1em;
    text-decoration: underline;
}
.meetingKnowledge {
    margin-right: 1em;
}

.uploadMeetingDateHeader {
    margin-right: 1em;
    margin-left: 1em;
}

.ownerNameHeader {
    margin-right: 1em;
    margin-left: 1em;
}

.ownerPhoneHeader {
    margin-right: 1em;
    margin-left: 1em;
}

.ownerMailHeader {
    margin-right: 1em;
    margin-left: 1em;
}

.meetingParticipantsLabel {
    margin-right: 1em;
}

.lastSaved {
    margin-right: 1em;
}

.lastSavedDate {
    margin-right: 1em;
}

.lastSavedUser {
    margin-right: 1em;
}

.participantHeader {
    margin-right: 0.5em;
    cursor: pointer;
}

#search {
    margin-left: 1.5em;
    height: 1em;
}

.meetingParticipants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 30em;
}

.renameDialogContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1em;
}

.buttonTooltip.rtl {
    direction: rtl;
}