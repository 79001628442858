.myMeetings {
    direction: rtl;
    flex-direction: column;
    display: flex;
    width: 75%;
    height: calc(100vh - 4em);
    overflow-y: auto;
    margin-right: auto;
    margin-left: auto;
    background-color: whitesmoke;
    margin-top: 3em;
    padding-top: 1em;
    padding-right: 2em;
    padding-left: 2em;
    min-width: 69em;
}

.newFolder {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    padding-left: 1.5em;
}

.newFolder span {
    margin-left: 0.5em;
}

.ReactDraggableTree_toggler {
    transform: scaleX(-1);
}

.meetingsDescription {
    margin: 1em;
    align-self: center;
}


.meetingsHeader {
    display: flex;
    align-items: center;
}

.meetingFilter {
    display: flex;
    height: 30px;
    align-items: center;
}

.meetingFilter .filterInput {
    height: 100%;
    width: 300px;
}

.meetingFilter .MuiOutlinedInput-input {
    padding: 6px;
}


.meetingSort {
    display: flex;
    align-items: center;
}

.sortLabel, .filterLabel {
    margin-right: 3em;
    margin-left: 0.5em;
    font-weight: bold;
}

.sort {
    direction: rtl;
    width: 12em;
}

.sort .MuiSelect-root {
    padding: 7px;
}

.sortOption {
    direction: rtl;
}

.myMeetingHeaders {
    display: flex;
    flex-direction: row;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    align-items: center;
    padding-right: 0.8em;
    padding-left: 0.8em;
}

.numberOfParticipantsHeader {
    width: 5em;
}

.meetingLengthHeader {
    width: 8em;
}

.recordingNameHeader {
    width: 6em;
}

.meeting-cell {
    display: flex;
    align-items: center;
    width: 100%;
}
