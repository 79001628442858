.coloredPlayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    direction: ltr;
}

.playerControls{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.5em;
}

.playerControls .MuiIconButton-root {
    padding: 1px;
    margin: 2px;
    background-color: white;
    color: #d82b42;
}

.coloredPlayerWrapper {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 2em;
    align-self: center;
    user-select: none;
    
    background-color: #FFBD1E;
    border-radius: 2em/50%;
    padding-right: 0.5em;
    padding-left: 0.5em;
}

.playerButtonOnMobile {
    display: none !important;
}

@media only screen and (max-width: 600px) {
    .coloredPlayerWrapper {
        bottom: 0;
        border-radius: 0;
    }

    .playerButtonOnComputer {
        display: none !important;
    }

    .playerButtonOnMobile {
        display: unset !important;
    }
}