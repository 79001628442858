.speakerItemWithoutCheck {
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
}

.speakerItemWithoutCheck > * {
	margin-left: 1em;
	margin-top: 0.2em;
}

.speakerItem {
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 2em;
}

.speakerItem > * {
	margin-left: 1em;
}

.speakerData {
	display: flex;
	flex-direction: column;
}

.speakerRanges {
	display: flex;
	flex-direction: row;
	max-width: 45em;
	padding-left: 2px;
	padding-right: 2px;
	overflow-x: auto;
}

.carousel .slide {
	background-color: transparent !important;
}

.carousel-root {
	direction: ltr;
}

.control-dots {
	margin-top: -2em !important;
	margin-bottom: 0em !important;
    padding-left: 1.2em;
	padding-right: 0.8em;
	position: relative !important;
	bottom: unset !important;
}

.carousel-status {
	visibility: hidden;
}

.thumbs-wrapper {
	display: none;
}

.carousel .control-next.control-arrow:before {
    border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000 !important;
}