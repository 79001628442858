.newMeeting {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 5.5em);
    width: 60%;
    min-width: 45em;
    padding-top: 2.5em;
    margin-right: auto;
    margin-left: auto;
    margin-top: 3em;
    overflow-y: auto;
}

.waitingRecordingList {
    margin-top: 3.5em;
    display: flex;
    flex-direction: column;
}

.newMeetingDescription {
    margin: 2em;
}

.waitingRecordingsHeaders {
    display: flex;
    flex-direction: row;
    margin-top: 2em;
    margin-bottom: 0.2em;
    align-items: center;
    padding-right: 0.8em;
    padding-left: 0.8em;
}

.meetingDateWaitingHeader {
    margin-right: 3em;
    width: 7em;
}

.costumerNameWaitingHeader {
    width: 7.5em;
}

.numberOfRecordingsWaitingHeader {
    width: 1.5em;
}

.editWaitingHeader {
    width: 3em;
}

.newMeeting .speakWithAgent {
    margin: 0;
    text-align: center;
}
