.rangePicker {
	direction: rtl;
	margin-left: 1em;
	border-radius: 1em;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding-right: 3px;
}

.rangePickerWrapper {
	padding-bottom: 2em;
	padding-right: 2em;
	padding-left: 1.2em;
}