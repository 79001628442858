.MuiDialogTitle-root {
	direction: rtl;
    padding-bottom: 0;
}

.MuiDialogContent-root {
	direction: rtl;
	.addNewTranscriber {
		color: gray;
	}
}