.inputDialog {
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputDialog .title {
    margin-bottom: 1em;
}

.dialogDescription {
    margin-bottom: 1em;
}