.VideoPlayerComponent {
  position: fixed;
  z-index: 10;
  top: 140px;
  left: 10px;
  cursor: move;
  .videoPlayerContainer {
    position: relative;
    width: 460px;
    height: 320px;
    video {
      width: 100%;
      height: 100%;
      background-color: black;
    }
    .videoSubtitles {
      position: absolute;
      bottom: 4px;
      color: white;
      text-align: center;
      font-size: 17px;
      font-weight: 600;
      text-shadow: 0 0 5px #000;
      background: black;
      direction: rtl;
      width: 100%;
      display: flex;
      flex-direction: column;
      .rangeString {
        margin-bottom: 3px;
        &.overlapping {
          color: red;
        }
        &.ltr {
          direction: ltr;
        }
        .passedWords {
          color: #007eff;
        }
      }
    }
  }
}
