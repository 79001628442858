.coloredSeekBar{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.coloredRange {
    z-index: 5;
}

.colorsBar{
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 0.9em;
    background-color: white;
    padding-left: 2px;
    padding-right: 2px;
    width: 100%;
}

.MuiSlider-rail{
    opacity: 0 !important;
}

.MuiSlider-track{
    opacity: 0 !important;
}

.MuiSlider-thumb {
    height: 21px !important;
    color: black;
    border-radius: 0 !important;
    width: 3px !important;
    z-index: 4 !important;
    margin-left: 0 !important;
    bottom: 1em;
}

.MuiSlider-root {
    z-index: 1 !important;
}

.MuiSlider-valueLabel {
    top: -12px !important;
    font-weight: bold !important;
}

.MuiSlider-valueLabel * {
    background-color: transparent !important;
    color: black !important;
}

.MuiSlider-marked {
    margin-bottom: 0px !important;
}

.MuiSlider-markLabel {
    font-weight: bold !important;
    color: black !important;
    font-size: 12px !important;
    top: 22px !important;
}

.coloredRange .react-contextmenu-wrapper {
    margin-bottom: 0 !important;
    max-width: 100% !important;
    width: 100%;
    height: 100%;
}

.coloredRange .react-contextmenu {
    top: 0 !important;
    left: 100% !important;
}

@media only screen and (max-width: 600px) {
    .coloredSeekBar {
        transform: scaleX(0.90);
    }

    .MuiSlider-markLabel {
        top: 30px !important;
    }

    .MuiSlider-mark {
        height: 0 !important;
    }
}