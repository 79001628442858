#root {
  margin: 0;
  background-image: url("assets/background.PNG");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

body {
  margin: 0;
  overflow: hidden;
}

* {
  font-family: 'Gill Sans', sans-serif;
}

.title {
  font-size: 1.5em;
  font-weight: bolder;
  align-self: center;
}

.MuiDialog-root {
    backdrop-filter: blur(3px);
}

#dialogButton {
  margin-top: 1em;
  margin-bottom: 1em;
  background-color: #d82b42;
  color: white;
  border-radius: 2.8em;
}

.errorPlaceHolder::placeholder {
	color: red !important;
	font-weight: bold !important;
}

.version {
  position: fixed;
  right: 25px;
  bottom: 5px;
  font-size: 12px;
}

@media only screen and (max-width: 600px) {
  body {
    overflow-y: auto;
  }
}
