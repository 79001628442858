.inputDialog {
    direction: rtl;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.conactDescriptio {
    margin-top: 1em;
    margin-bottom: 1em;
    font-weight: bold;
    font-size: 18px;
}

#dialogButton {
    margin-top: 1em;
    margin-bottom: 1em;
    background-color: #d82b42;
    color: white;
    border-radius: 2.8em;
}

.withLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 20em;
    margin-bottom: 0.3em;
}