.speakersList {
	display: flex;
	flex-direction: column;
	margin-bottom: 2em;
	margin-right: 2em;
	width: 100%;
}

.speakerData .MuiInputBase-root {
	width: 15em;
	height: 2.2em;
    background-color: white;
	border: 1px solid black;
}

.addSpeakerButton {
	margin-top: 1em;
	margin-bottom: 7em;
	margin-right: 2em;
}

.addSpeakerButton > button { 
	border: none !important;
}

@media only screen and (max-width: 600px) {
	.speakersList {
		margin-right: 0.5em;
	}
}