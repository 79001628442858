.recieptDialog {
	padding: 1em;
}

.recieptDialog .MuiDialogActions-root {
	justify-content: center;
}

.recieptDialog .MuiButton-root {
	border: 1px solid #d82b42;
    color: #d82b42;
    font-weight: bold;
    padding: 0.5em 1.2em;
}